<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Services text" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-card class="mb-3" title="Top Image">
                  <div v-if="topImageExisting">
                    <a style="display: block;" target="_blank" :href="topImageExisting"><img style="max-width: 400px;" :src="topImageExisting"></a>
                    <div><va-button small color="danger" @click="topImageExisting = deleteImage(topImageId) ? '' : topImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.topImage"
                    v-model="topImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('topImage', 'ServicesTopImage', 'Services[topImage]', topImage[0])">Завантажити</va-button>
                  <div v-if="errors.topImage" style="color: red;">{{ errors.topImage[0] }}</div>
                </va-card>
                <va-input
                  label="Breadcrumb Label"
                  v-model="breadcrumbLabel"
                  @input="delete errors.breadcrumbLabel"
                  :error="!!errors.breadcrumbLabel"
                  :error-messages="errors.breadcrumbLabel"
                />
                <va-input
                  label="Title Text"
                  v-model="titleText"
                  @input="delete errors.titleText"
                  :error="!!errors.titleText"
                  :error-messages="errors.titleText"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Всі послуги"
                  v-model="allServicesButtonText"
                  @input="delete errors.allServicesButtonText"
                  :error="!!errors.allServicesButtonText"
                  :error-messages="errors.allServicesButtonText"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                  @input="delete errors.metaTitle"
                  :error="!!errors.metaTitle"
                  :error-messages="errors.metaTitle"
                />
                <va-input
                  label="Meta Description"
                  v-model="metaDescription"
                  @input="delete errors.metaDescription"
                  :error="!!errors.metaDescription"
                  :error-messages="errors.metaDescription"
                />
                <va-input
                  label="OG Title"
                  v-model="OgTitle"
                  @input="delete errors.OgTitle"
                  :error="!!errors.OgTitle"
                  :error-messages="errors.OgTitle"
                />
                <va-input
                  label="OG Description"
                  v-model="OgDescription"
                  @input="delete errors.OgDescription"
                  :error="!!errors.OgDescription"
                  :error-messages="errors.OgDescription"
                />
                <va-card class="mb-3" title="OG Image">
                  <div v-if="OgImageExisting">
                    <a style="display: block;" target="_blank" :href="OgImageExisting"><img style="max-width: 400px;" :src="OgImageExisting"></a>
                    <div><va-button small color="danger" @click="OgImageExisting = deleteImage(OgImageId) ? '' : OgImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="OgImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', null, 'SeoEntity[image]', OgImage[0], 'common\\models\\SeoEntity')">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-input
                  label="seo text title"
                  v-model="seoTextTitle"
                  @input="delete errors.seoTextTitle"
                  :error="!!errors.seoTextTitle"
                  :error-messages="errors.seoTextTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>seo text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="seo text see more text"
                  v-model="seoTextSeeMore"
                  @input="delete errors.seoTextSeeMore"
                  :error="!!errors.seoTextSeeMore"
                  :error-messages="errors.seoTextSeeMore"
                />
                <va-input
                  label="Seo Text Hide Text"
                  v-model="seoTextHide"
                  @input="delete errors.seoTextHide"
                  :error="!!errors.seoTextHide"
                  :error-messages="errors.seoTextHide"
                />
                <va-input
                  label="Children seo title template"
                  v-model="childrenSeoTitleTemplate"
                  @input="delete errors.childrenSeoTitleTemplate"
                  :error="!!errors.childrenSeoTitleTemplate"
                  :error-messages="errors.childrenSeoTitleTemplate"
                  :messages="['Available macroses: {label}']"
                />
                <va-input
                  label="Children seo description template"
                  v-model="childrenSeoDescriptionTemplate"
                  @input="delete errors.childrenSeoDescriptionTemplate"
                  :error="!!errors.childrenSeoDescriptionTemplate"
                  :error-messages="errors.childrenSeoDescriptionTemplate"
                  :messages="['Available macroses: {label}']"
                />
                <va-input
                  type="textarea"
                  label="Children default micromark"
                  v-model="childrenDefaultMicromark"
                  @input="delete errors.childrenDefaultMicromark"
                  :error="!!errors.childrenDefaultMicromark"
                  :error-messages="errors.childrenDefaultMicromark"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      topImage: [],
      topImageExisting: '',
      topImageId: '',
      breadcrumbLabel: '',
      titleText: '',

      allServicesButtonText: '',

      seoId: null,
      metaTitle: '',
      metaDescription: '',
      OgTitle: '',
      OgDescription: '',
      OgImage: [],
      OgImageId: '',
      OgImageExisting: '',
      seoTextTitle: '',
      seoText: '',
      seoTextSeeMore: '',
      seoTextHide: '',

      childrenSeoTitleTemplate: '',
      childrenSeoDescriptionTemplate: '',
      childrenDefaultMicromark: '',

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_1LK_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      errors: [],

      tabTitles: ['Main', 'One service', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    apiUrl () {
      return process.env.VUE_APP_1LK_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.topImage || !!this.errors.breadcrumbLabel || !!this.errors.titleText
      } else if (tab === 1) {
        return !!this.errors.allServicesButtonText
      }
      return false
    },
    uploadImage (attr, entityAttribute, param, file, model = 'common\\modules\\kvs\\models\\Services') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (entityAttribute === null) {
        formData.append('id', this.seoId ? this.seoId : '0')
      } else {
        formData.append('id', '0')
        formData.append('entity_attribute', entityAttribute)
      }

      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_1LK_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        titleText: this.titleText,
        allServicesButtonText: this.allServicesButtonText,
        breadcrumbLabel: this.breadcrumbLabel,
        childrenSeoTitleTemplate: this.childrenSeoTitleTemplate,
        childrenSeoDescriptionTemplate: this.childrenSeoDescriptionTemplate,
        childrenDefaultMicromark: this.childrenDefaultMicromark,

        SeoEntity: {
          title: this.metaTitle,
          description: this.metaDescription,
          og_title: this.OgTitle,
          og_description: this.OgDescription,
          seo_text_title: this.seoTextTitle,
          seo_text: this.seoText,
          seo_text_see_more_text: this.seoTextSeeMore,
          seo_text_hide_text: this.seoTextHide,
        },
      }
      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/services-text?lang=${this.locale}`, data)
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/services-text?lang=${this.locale}`)
        .then(response => {
          this.topImageExisting = response.data.topImage ? process.env.VUE_APP_1LK_ADMIN + response.data.topImage : ''
          this.topImageId = response.data.topImageId
          this.titleText = response.data.titleText
          this.allServicesButtonText = response.data.allServicesButtonText
          this.breadcrumbLabel = response.data.breadcrumbLabel
          this.childrenSeoTitleTemplate = response.data.childrenSeoTitleTemplate
          this.childrenSeoDescriptionTemplate = response.data.childrenSeoDescriptionTemplate
          this.childrenDefaultMicromark = response.data.childrenDefaultMicromark

          this.seoId = response.data.seo.id
          this.metaTitle = response.data.seo.title
          this.metaDescription = response.data.seo.description
          this.OgTitle = response.data.seo.og_title
          this.OgDescription = response.data.seo.og_description
          this.seoTextTitle = response.data.seo.seo_text_title
          this.seoText = response.data.seo.seo_text ? response.data.seo.seo_text : ''
          this.seoTextSeeMore = response.data.seo.seo_text_see_more_text
          this.seoTextHide = response.data.seo.seo_text_hide_text

          this.OgImageExisting = response.data.seo.og_image ? process.env.VUE_APP_1LK_ADMIN + response.data.seo.og_image : null
          this.OgImageId = response.data.seo.og_image_id
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
